'use client';

import { useState } from 'react';
import { HiChevronDown } from 'react-icons/hi';
import Link from 'next/link';
import { DocumentRendererProps } from '@keystone-6/document-renderer';
import { ContentRenderer } from '@portal/content-renderer';

import { Faq } from 'src/services/keystone-types';

type Props = {
    item: Faq;
};

const renderers: DocumentRendererProps['renderers'] = {
    block: {
        paragraph: ({ children }) => {
            return <p className="mb-2">{children}</p>;
        },
    },
    inline: {
        link: ({ href, children }) => (
            <Link href={href} className="text-third underline hover:no-underline">
                {children}
            </Link>
        ),
    },
};

export default function FAQSectionItem({ item }: Props) {
    const [collapsed, setCollapsed] = useState(true);

    return (
        <>
            <div className="text-primary relative">
                <HiChevronDown
                    aria-hidden
                    onClick={() => setCollapsed(!collapsed)}
                    className={`absolute right-0 top-1 cursor-pointer text-2xl transition duration-200 ease-out ${
                        collapsed ? '' : ' rotate-180'
                    }`}
                />
                <div className="cursor-pointer gap-4 md:flex" onClick={() => setCollapsed(!collapsed)}>
                    <h2 className="font-body pr-4 text-lg decoration-1 underline-offset-4 hover:underline md:w-3/4 md:pr-0 md:text-xl">
                        {item.name}
                    </h2>
                </div>
            </div>
            {!collapsed && (
                <div className="mt-4 text-sm">
                    <ContentRenderer content={item.content} renderers={renderers} />
                </div>
            )}
        </>
    );
}
