'use client';
import { useEffect } from 'react';
import { usePathname, useSearchParams } from 'next/navigation';

import { gaPageView } from '../services/gtag';

export default function GaTrackingNotifications({ gaTrackingId }) {
    const pathname = usePathname();
    const searchParams = useSearchParams();

    useEffect(() => {
        const sp = searchParams?.toString();
        gaPageView(`${pathname}${sp ? `?${sp}` : ''}`, gaTrackingId);
    }, [gaTrackingId, pathname, searchParams]);

    return null;
}
