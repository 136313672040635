'use client';

import { PropsWithChildren, createContext, useContext, useMemo } from 'react';
import { signOut, useSession } from 'next-auth/react';
import { fetchJson } from 'lib/fetch-json';
import { useRouter } from 'next/navigation';
import { myAccountUrl } from 'src/services/route-utils';
import { UserRoleType } from 'src/types/users';
import toast from 'src/components/TailwindToaster';

export type UserType = {
    id: number;
    email: string;
    username: string;
    first_name: string;
    last_name: string;
    date_created: string;
    activated: number;
    email_sent: number;
    secret: string;
    email_disabled: number;
    account_disabled: number;
    developer_enrolled: number;
    roles: UserRoleType[];
    settings?: {
        results_notifications?: boolean;
        active_columns: string[];
        last_role?: UserRoleType;
    };
};

type GlobalStateType = {
    user: UserType | null;
    setLoadUser: () => Promise<UserType | null>;
    logout: () => void;
    switchRole: (role: UserRoleType) => void;
    impersonate: (username: string) => void;
    stopImpersonate: () => void;
};

const GlobalStateContext = createContext<GlobalStateType>({
    user: null,
    setLoadUser: async () => null,
    logout: () => {},
    switchRole: () => {},
    impersonate: () => {},
    stopImpersonate: () => {},
});

export function GlobalStateProvider(props: PropsWithChildren) {
    const { data, update } = useSession();
    const router = useRouter();

    const value = useMemo<GlobalStateType>(
        () => ({
            user: data?.user as any,
            setLoadUser: () => update().then((data: any) => data?.user || null),
            logout: async () => {
                signOut({ redirect: false }).finally(async () => {
                    await update();
                    router.replace(myAccountUrl());
                });
            },
            switchRole: (role: UserRoleType) =>
                fetchJson('/json/settings/switch-role', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ role }),
                }).then(async () => {
                    await update();
                    router.replace(myAccountUrl('/rezultate'));
                    router.refresh();
                }),
            stopImpersonate: () =>
                fetchJson(`/json/users/${(data as any)?.user?.username}/impersonate`, {
                    method: 'DELETE',
                    headers: { 'Content-Type': 'application/json' },
                })
                    .then(async () => {
                        window.location.href = myAccountUrl();
                    })
                    .catch(() => {
                        toast.error('Eroare la întreruperea impersonării');
                    }),
            impersonate: (username: string) =>
                fetchJson(`/json/users/${username}/impersonate`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                })
                    .then(async () => {
                        window.location.href = myAccountUrl();
                    })
                    .catch(() => {
                        toast.error('Eroare la încercarea impersonării');
                    }),
        }),
        [data, update, router],
    );

    return <GlobalStateContext.Provider value={value} {...props} />;
}

// a hook which we are going to use whenever we need data from `GlobalStateProvider`
export default function useGlobalState() {
    const context = useContext(GlobalStateContext);

    if (!context) {
        throw new Error('You need to wrap GlobalStateProvider.');
    }

    return context;
}
