import Link from 'next/link';
import { HiOutlineSwitchHorizontal } from 'react-icons/hi';

import { UserRoleType } from 'src/types/users';
import useGlobalState from 'src/hooks/useGlobalState';

export const titleForRole = (role: UserRoleType) => {
    switch (role) {
        case UserRoleType.PACIENT:
            return 'Pacient';
        case UserRoleType.DOCTOR:
            return 'Medic trimițător';
        case UserRoleType.LAB:
            return 'Clinică';
        case UserRoleType.ADMIN:
            return 'Administrator';
        default:
            return role;
    }
};

export const SwitchRoleMenuItems = ({ closeAllMenus }) => {
    const { user, switchRole } = useGlobalState();

    const last = user?.settings?.last_role;

    if (!last) {
        return null;
    }

    const roles = user?.roles?.filter((r) => ![UserRoleType.FORTAT, last].includes(r));

    return roles?.map((r: UserRoleType) => (
        <li className="border-dark-green/25 border-b" key={r}>
            <Link
                href=""
                onClick={() => {
                    switchRole(r);
                    closeAllMenus();
                }}
                className="hover:bg-light-green block p-4 md:px-6"
                aria-label={titleForRole(r)}>
                <HiOutlineSwitchHorizontal
                    title={titleForRole(r)}
                    className="text-primary mr-2 inline-block md:text-base"
                />
                {titleForRole(r)}
            </Link>
        </li>
    ));
};
